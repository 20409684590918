import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get, filter, find } from "lodash";
import { connect } from "react-redux";

import {
    InputButton,
    FormWrapper,
    DatePickerBoxWithState,
    SelectBoxWithState,
    RadioButtonWithState,
    InputBoxWithState
} from "../../../../components/boda-boda/Forms";
import { codStatuses, currency, deliveryTypes, mapPackageStatusesNames, reconciliationReportStatuses, weightMetric } from "../../../../config/boda-boda/db_config/db_status.config";
import {
    isEmptyValue,
    fromObjectToQueryString,
    downloadCSV,
    roundValue,
    convertToCsvValue
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn, changeInputFn } from "../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { reconciliationReportAPI, merchantAPI, getCityListAPI, getZoneAPI, getUserDriverList, getAllFacilityAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { setApiResponse } from "../../../../store/actions";
import { setFlashMessage } from '../../../../store/boda-boda/ui/actions'


class ReconciliationReport extends Component {

    onGenerateFn = () => {
        let { formState, history } = this.props;

        if (this.validateFn() === true) {
            let dateTo = new Date(get(formState, "reconciliation_search.dateTo"));
            dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
            dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

            let deliveredDateTo = new Date(get(formState, "reconciliation_search.deliveredDateTo"));
            deliveredDateTo = new Date(deliveredDateTo.setDate(deliveredDateTo.getDate() + 1));
            deliveredDateTo = new Date(deliveredDateTo.setSeconds(deliveredDateTo.getSeconds() - 1));

            let failedDeliveryDateTo = new Date(get(formState, "reconciliation_search.failedDeliveryDateTo"));
            failedDeliveryDateTo = new Date(failedDeliveryDateTo.setDate(failedDeliveryDateTo.getDate() + 1));
            failedDeliveryDateTo = new Date(failedDeliveryDateTo.setSeconds(failedDeliveryDateTo.getSeconds() - 1));

            const queryObj = {
                dateFrom: get(formState, "reconciliation_search.dateFrom").toISOString(),
                dateTo: dateTo.toISOString(),
                deliveredDateFrom: get(formState, "reconciliation_search.deliveredDateFrom") ? get(formState, "reconciliation_search.deliveredDateFrom").toISOString() : undefined,
                deliveredDateTo: isEmptyValue(get(formState, "reconciliation_search.deliveredDateTo")) ? undefined : deliveredDateTo.toISOString(),
                failedDeliveryDateFrom: get(formState, "reconciliation_search.failedDeliveryDateFrom") ? get(formState, "reconciliation_search.failedDeliveryDateFrom").toISOString() : undefined,
                failedDeliveryDateTo: isEmptyValue(get(formState, "reconciliation_search.failedDeliveryDateTo")) ? undefined : failedDeliveryDateTo.toISOString(),
                deliveryType: get(formState, "reconciliation_search.deliveryType"),
                status: get(formState, "reconciliation_search.status"),
                merchantId: get(formState, "reconciliation_search.merchantId").split("/")[0],
                zoneId: get(formState, "reconciliation_search.zoneId").split("/")[0],
                cityId: get(formState, "reconciliation_search.cityId"),
                customerName: get(formState, "reconciliation_search.customerName", ""),
                channelOrderNo: get(formState, "reconciliation_search.channelOrderNo", ""),
                deliveredBy: get(formState, "reconciliation_search.deliveredBy", "")?.split("/")[0],
                codSettlementStatus: get(formState, "reconciliation_search.codSettlementStatus"),
                locationFinalFacilityId: get(formState, "reconciliation_search.locationFinalFacilityId")?.split("/")[0],
            }

            if (get(formState, "reconciliation_search.type", "screen") === "screen") {
                history.push("/reports/lodgement-reconciliation-report/view");
            } else {
                this.getAPIData(fromObjectToQueryString(queryObj));
            }
        }
    }

    validateFn = () => {
        let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (isEmptyValue(get(formState, "reconciliation_search.dateFrom", ""))) {
            errors.push({
                "property": "dateFrom",
                "error": "The Pick Up Date From field is required"
            })
        }

        if (isEmptyValue(get(formState, "reconciliation_search.dateTo", ""))) {
            errors.push({
                "property": "dateTo",
                "error": "The Pick Up Date To field is required"
            })
        }

        if (!isEmptyValue(get(formState, "reconciliation_search.deliveredDateFrom", "")) || !isEmptyValue(get(formState, "reconciliation_search.deliveredDateTo", ""))) {
            if (isEmptyValue(get(formState, "reconciliation_search.deliveredDateFrom", ""))) {
                setFormErrorFn("reconciliation_search", [{
                    "property": "deliveredDateFrom",
                    "error": "Delivery date from is required if delivery date to is entered"
                }]);

                return false;
            }
            if (isEmptyValue(get(formState, "reconciliation_search.deliveredDateTo", ""))) {
                setFormErrorFn("reconciliation_search", [{
                    "property": "deliveredDateTo",
                    "error": "Delivery date to is required if delivery date to is entered"
                }]);

                return false;
            }
            if (new Date(get(formState, "reconciliation_search.deliveredDateFrom", "")) > new Date(get(formState, "reconciliation_search.deliveredDateTo", ""))) {
                setFormErrorFn("reconciliation_search", [{
                    "property": "deliveredDateTo",
                    "error": "The delivery date to To field should not be less than the delivery date to From"
                }]);

                return false;
            }
        }

        if (!isEmptyValue(get(formState, "reconciliation_search.failedDeliveryDateFrom", "")) || !isEmptyValue(get(formState, "reconciliation_search.failedDeliveryDateTo", ""))) {
            if (isEmptyValue(get(formState, "reconciliation_search.failedDeliveryDateFrom", ""))) {
                setFormErrorFn("reconciliation_search", [{
                    "property": "failedDeliveryDateFrom",
                    "error": "Failed delivery date from is required if failed delivery date to is entered"
                }]);

                return false;
            }
            if (isEmptyValue(get(formState, "reconciliation_search.failedDeliveryDateTo", ""))) {
                setFormErrorFn("reconciliation_search", [{
                    "property": "failedDeliveryDateTo",
                    "error": "Failed delivery date to is required if failed delivery date from is entered"
                }]);

                return false;
            }
            if (new Date(get(formState, "reconciliation_search.failedDeliveryDateFrom", "")) > new Date(get(formState, "reconciliation_search.failedDeliveryDateTo", ""))) {
                setFormErrorFn("reconciliation_search", [{
                    "property": "failedDeliveryDateTo",
                    "error": "The failed delivery date to field should not be less than the failed delivery date from"
                }]);

                return false;
            }
        }

        if (errors.length === 0) {
            if (new Date(get(formState, "reconciliation_search.dateFrom", "")) > new Date(get(formState, "reconciliation_search.dateTo", ""))) {
                setFormErrorFn("reconciliation_search", [{
                    "property": "dateTo",
                    "error": "The Pick Up Date To field should not be less than the Pick Up Date From"
                }]);

                return false;
            } else {
                setFormErrorFn("reconciliation_search", []);
                return true;
            }
        } else {
            setFormErrorFn("reconciliation_search", errors);
            return false;
        }
    }

    getAPIData = (queryString) => {
        let { setPageLoader, setFlashMessage } = this.props;

        setPageLoader(true);

        callApi(`${reconciliationReportAPI.url}?${queryString}&isJsonFile=true`)
            .method("get")
            .headers(true)
            .send()
            .then(async (result) => {
                if (get(result, "_statue", "") === false) {
                    console.log('false')
                    if (get(result, "data.statusCode", 0) === 403) {
                        setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        });
                    } else {
                        setFlashMessage({
                            status: true,
                            message: "Something went wrong",
                            type: "danger",
                        });
                    }
                    setPageLoader(false);
                } else {
                    if (result?.data?.data?.isJsonFile) {
                        const response = await fetch(result?.data?.data?.url);

                        if (!response.ok) {
                            throw new Error('Wrong URL provided');
                        }

                        result.data = await response.json();
                    }
                    this.generateCSVData(get(result, "data.data", []), get(result, "data.meta.totalsRow", {}));
                    setPageLoader(false);
                }
            })
            .catch((error) => {
                setPageLoader(false);
            })
    }

    displayAttempts = (value) => {
        let finalString = '';
        let counter = 1;
        for (let index = 0; index < value?.packageHistories?.length; index++) {
            if (value?.packageHistories[index]?.status === "DELIVERED" || value?.packageHistories[index]?.status === "NOT_DELIVERED") {
                finalString += `${counter}) ${dateObjectToString(value?.packageHistories[index]?.updatedAt, "YY-mm-dd HH:MM:II")} - ${value?.packageHistories[index]?.driver?.name} - ${value?.packageHistories[index]?.status === "DELIVERED" ? "Delivered" : "Failed Delivery"}  `
                counter++;
            }
        }
        return finalString;
    }

    generateCSVData = (data = [], totalData) => {
        let { formState } = this.props;

        const reportName = ["Lodgement Reconciliation Report"];
        const dateRange = [`From ${dateObjectToString(get(formState, "reconciliation_search.dateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "reconciliation_search.dateTo"), "dd/mm/YY")}`];
        const deliveredDateRange = [`"Delivered From ${get(formState, "reconciliation_search.deliveredDateFrom") ? `${dateObjectToString(get(formState, "reconciliation_search.deliveredDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "reconciliation_search.deliveredDateTo"), "dd/mm/YY")}` : "All"}"`];
        const failedDeliveryDateRange = [`"Failed Delivery From ${get(formState, "reconciliation_search.failedDeliveryDateFrom") ? `${dateObjectToString(get(formState, "reconciliation_search.failedDeliveryDateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "reconciliation_search.failedDeliveryDateTo"), "dd/mm/YY")}` : "All"}"`];
        const merchant = [`"Merchant: ${convertToCsvValue(get(formState, "reconciliation_search.merchantId", "") === "" ? "All" : get(formState, "reconciliation_search.merchantId", "").split("/")[1])}"`];
        const zone = [`"Zone: ${convertToCsvValue(get(formState, "reconciliation_search.zoneId", "") === "" ? "All" : get(formState, "reconciliation_search.zoneId", "").split("/")[1])}"`];
        const city = [`"City: ${convertToCsvValue(get(formState, "reconciliation_search.cityId", "") === "" ? "All" : get(formState, "reconciliation_search.selectedCity", ""))}"`];
        const deliveryType = [`"Delivery Type: ${convertToCsvValue(get(formState, "reconciliation_search.deliveryType", "") === "" ? "All" : get(formState, "reconciliation_search.deliveryType", ""))}"`];
        const packageStatus = [`"Package Status: ${convertToCsvValue(get(formState, "reconciliation_search.status", "") === "" ? "All" : mapPackageStatusesNames[get(formState, "reconciliation_search.status", "")])}"`];
        const customerName = [`"Customer Name: ${convertToCsvValue(get(formState, "reconciliation_search.customerName", "") === "" ? "All" : get(formState, "reconciliation_search.customerName", ""))}"`];
        const orderNo = [`"Order Number: ${convertToCsvValue(get(formState, "reconciliation_search.channelOrderNo", "") === "" ? "All" : get(formState, "reconciliation_search.channelOrderNo", ""))}"`];
        const lastMileDriver = [`"Last Mile Driver: ${convertToCsvValue(get(formState, "reconciliation_search.deliveredBy", "") === "" ? "All" : get(formState, "reconciliation_search.deliveredBy", "").split("/")[1])}"`];
        const codSettlementStatus = [`"COD Status: ${convertToCsvValue(get(formState, "reconciliation_search.codSettlementStatus", "") === "" ? "All" : get(formState, "reconciliation_search.codSettlementStatus", ""))}"`];
        const locationFinalFacilityId = [`"Branch: ${convertToCsvValue(get(formState, "reconciliation_search.locationFinalFacilityId", "") === "" ? "All" : get(formState, "reconciliation_search.locationFinalFacilityId", "").split("/")[1])}"`];
        const headers = ["Pick Up Date", "Article ID", "Tracking Number", "Consignment ID", "Order Number", "Manifest Code", "Customer Name", "Address", "City", "Customer Contact Number", "Zone", "Merchant Code", "Merchant Branch", "Delivery Type", "XL Package",
            "Payment Type", "Lodgement Location", "Last Mile Driver", "Destination Delivery Center", "Status", "Closure Date/Time", "Status Reason", "COD Status", "Hub Settlement Reference", "Merchant Settlement Reference", "Attempted Deliveries", `Actual Weight (${weightMetric})`, `Volumetric Weight (${weightMetric})`,
            `Order Amount (${currency})`, `Cash on Delivery Fee (${currency})`, `Card on Delivery Fee (${currency})`, `Express Fee (${currency})`, `XL Handling Fee (${currency})`, `Delivery Fee (${currency})`, `Delivery Flat Fee (${currency})`, `Total Delivery Fee (${currency})`, `Total Delivery Flat Fee (${currency})`,
        ];

        const dataList = [];

        data.forEach((value) => {
            const temp = [];
            temp.push(dateObjectToString(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")?.length !== 0 ? value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest.pickedAt : new Date(), "YY-mm-dd"));
            temp.push(`"${convertToCsvValue(get(value, "articleNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.trackingNumber", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.consignmentNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.channelOrderNo", "-"))}"`);
            temp.push(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST").length !== 0 ? `"${convertToCsvValue(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest.code)}"` : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.address", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.city.name", "-"))}"`);
            temp.push(get(value, "order.phone", "-"));
            temp.push(`"${convertToCsvValue(get(value, "order.shippingLocation.city.zone.name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "merchant.code", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "merchantBranch.name", "-"))}"`);
            temp.push(get(value, "deliveryType", "-"));
            temp.push(get(value, "xlHandling", false) === false ? "No" : "Yes");
            temp.push(get(value, "order.paymentMethod", "-") === "CASH_ON_DELIVERY" ? "CASH ON DELIVERY" : get(value, "order.paymentMethod", "-") === "CARD_ON_DELIVERY" ? "CARD ON DELIVERY" : get(value, "order.paymentMethod", "-") === "PREPAID" ? "PREPAID" : "-");
            temp.push(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST").length !== 0 ? `"${convertToCsvValue(value?.packageHistories.filter((packageHistory) => packageHistory.status === "IN_TRANSIT_FIRST")[0].manifest.locationDrop.name)}"` : "-");
            temp.push(`"${convertToCsvValue(get(value, "courier.staffs[0].name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "locationFinalFacility.name", "-"))}"`);

            temp.push(
                get(value, "status", "-") === "PACKED" ?
                    (value.onHold === true) ? "Packed" + "(On Hold)" : "Packed"
                    : get(value, "status", "-") === "IN_TRANSIT_FIRST" ?
                        (value.onHold === true) ? "Package Picked up" + "(On Hold)" : "Package Picked up"

                        : get(value, "status", "-") === "RECEIVED" ?
                            (value.onHold === true) ? "Received at processing Center" + "(On Hold)" : "Received at processing Center"

                            : get(value, "status", "-") === "SORTED" ?
                                (value.onHold === true) ? "Ready for Transit" + "(On Hold)" : "Ready for Transit"

                                : get(value, "status", "-") === "IN_TRANSIT_LINE" ?
                                    (value.onHold === true) ? "Intransit to regional hub" + "(On Hold)" : "Intransit to regional hub"

                                    : get(value, "status", "-") === "IN_TRANSIT_LAST" ?
                                        (value.onHold === true) ? "Out for Delivery" + "(On Hold)" : "Out for Delivery"

                                        : get(value, "status", "-") === "DELIVERED" ?
                                            (value.onHold === true) ? "Delivered" + "(On Hold)" : "Delivered"

                                            : get(value, "status", "-") === "NOT_DELIVERED" ?
                                                (value.onHold === true) ? "Failed Delivery" + "(On Hold)" : "Failed Delivery"
                                                : get(value, "status", "-") === "READY_FOR_RETURN" ?
                                                    (value.onHold === true) ? "Ready for Return" + "(On Hold)" : "Ready for Return"
                                                    : get(value, "status", "-") === "IN_TRANSIT_RETURN" ?
                                                        (value.onHold === true) ? "Return Intransit" + "(On Hold)" : "Return Intransit"
                                                        : get(value, "status", "-") === "NOT_RETURNED" ?
                                                            (value.onHold === true) ? "Failed Return" + "(On Hold)" : "Failed Return"
                                                            : get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                                                                (value.onHold === true) ? "Returned to Merchant" + "(On Hold)" : "Returned to Merchant"
                                                                : "-");
            temp.push(
                get(value, "status", "-") === "DELIVERED" ?
                    (get(value, "deliveredAt", "-") !== null) ? dateObjectToString(get(value, "deliveredAt", new Date()), "YY-mm-dd HH:MM:II") : "-"
                    : get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                        (get(value, "returnedAt", "-") !== null) ? dateObjectToString(get(value, "returnedAt", new Date()), "YY-mm-dd HH:MM:II") : "-"
                        : "-");

            temp.push(
                get(value, "status", "-") === "DELIVERED" ?
                    `"${convertToCsvValue(value.order.remarkDelivery)}"`
                    : get(value, "status", "-") === "NOT_DELIVERED" || get(value, "status", "-") === "READY_FOR_RETURN" || get(value, "status", "-") === "RETURNED_TO_MERCHANT" ?
                        `"${convertToCsvValue(value.notDeliveredReason)}"`
                        : "-");
            temp.push(`"${convertToCsvValue(get(value, "order.paymentMethod") === "CASH_ON_DELIVERY" ? get(value, "order.codSettlementStatus", "-") : "-")}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.hubSettlement.reference", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "order.headOfficeSettlement.reference", "-"))}"`);
            temp.push(`"${convertToCsvValue(this.displayAttempts(value))}"`);
            temp.push(get(value, "weight", "-"));
            temp.push(get(value, "volumetric", "-"));
            temp.push(isEmptyValue(get(value, "order.amount", "")) ? "-" : get(value, "order.amount", ""));
            temp.push(get(value, "order.paymentMethod", "") === "CASH_ON_DELIVERY" ? get(value, "order.cashDeliveryPrice", "-") : "0.00");
            temp.push(get(value, "order.paymentMethod", "") === "CARD_ON_DELIVERY" ? get(value, "order.cardDeliveryPrice", "-") : "0.00");
            temp.push(get(value, "expressFee", "-"));
            temp.push(get(value, "xlPackageHandlingFee", "-"));
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? get(value, "merchantPrice", "0") : "0");
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? "0" : get(value, "merchantFlatPrice", "0"));
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? get(value, "totalPrice", "0") : "0");
            temp.push(get(value, "priceType", "-") === "VL_RATE" ? "0" : get(value, "totalFlatPrice", "0"));
            // temp.push(get(value, "totalPrice", "-"));

            dataList.push(temp);
        });

        const finalDataSet = [reportName, [""], dateRange, deliveredDateRange, failedDeliveryDateRange, merchant, zone, city, deliveryType, packageStatus, customerName, orderNo, lastMileDriver, locationFinalFacilityId, codSettlementStatus, [""], [""], headers];

        dataList.forEach((value) => {
            finalDataSet.push(value);
        });

        finalDataSet.push([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Total",
            roundValue(get(totalData, "expressFee", "")),
            roundValue(get(totalData, "xlPackageHandlingFee", "")),
            roundValue(get(totalData, "merchantPrice", "")),
            roundValue(get(totalData, "merchantFlatPrice", "")),
            roundValue(get(totalData, "totalPrice", "")),
            roundValue(get(totalData, "totalFlatPrice", ""))
        ]);

        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        const fileName = `Reconciliation Report-${Date.now()}.csv`;
        downloadCSV(csvDataContent, fileName);
    }

    render() {
        let { changeInputFn, formState, setApiResponse } = this.props;
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Lodgement Reconciliation Report</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Lodgement Reconciliation Report</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className={"card"} style={{ marginBottom: "120px" }}>
                                <div className={"card-body"}>

                                    <FormWrapper
                                        setGroupName={"reconciliation_search"}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            dateFrom: null,
                                            dateTo: null,
                                            merchantId: "",
                                            deliveryType: "",
                                            status: "",
                                            zoneId: "",
                                            cityId: "",
                                            type: "screen",
                                            cityList: [],
                                            customerName: "",
                                            channelOrderNo: "",
                                            deliveredBy: "",
                                            deliveredDateFrom: null,
                                            deliveredDateTo: null,
                                            failedDeliveryDateFrom: null,
                                            failedDeliveryDateTo: null,
                                            locationFinalFacilityId: "",
                                            codSettlementStatus: "",
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Pick Up Date From"
                                                    inputName={"dateFrom"}
                                                    formGroupName={"reconciliation_search"}
                                                    placeholderText={"Pick Up Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Pick Up Date To"
                                                    inputName={"dateTo"}
                                                    formGroupName={"reconciliation_search"}
                                                    placeholderText={"Pick Up Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Delivered Date From"
                                                    inputName={"deliveredDateFrom"}
                                                    formGroupName={"reconciliation_search"}
                                                    placeholderText={"Delivered Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Delivered Date To"
                                                    inputName={"deliveredDateTo"}
                                                    formGroupName={"reconciliation_search"}
                                                    placeholderText={"Delivered Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Failed Delivery Date From"
                                                    inputName={"failedDeliveryDateFrom"}
                                                    formGroupName={"reconciliation_search"}
                                                    placeholderText={"Failed Delivery Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    labelText="Failed Delivery Date To"
                                                    inputName={"failedDeliveryDateTo"}
                                                    formGroupName={"reconciliation_search"}
                                                    placeholderText={"Failed Delivery Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText="Zone"
                                                    inputName={"zoneId"}
                                                    placeHolderText={"Zone"}
                                                    formGroupName={"reconciliation_search"}
                                                    apiUrl={`${getZoneAPI.url}/all`}
                                                    apiStateKey={getZoneAPI.key}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                    onChangeFn={(eventData) => {
                                                        if (eventData.value !== "") {
                                                            let zoneName = eventData.value.split("/")
                                                            const cities = filter(
                                                                get(formState, `reconciliation_search.cityList`, []),
                                                                (e) => e.zoneId === parseInt(zoneName[0])
                                                            );

                                                            setApiResponse(getCityListAPI.key, [{ id: "", name: "All" }, ...cities])
                                                        } else {
                                                            setApiResponse(getCityListAPI.key, get(formState, `reconciliation_search.cityList`, []))
                                                        }
                                                        changeInputFn("reconciliation_search", "cityId", "", "")
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText="City"
                                                    inputName={"cityId"}
                                                    placeHolderText={"City"}
                                                    formGroupName={"reconciliation_search"}
                                                    apiUrl={`${getCityListAPI.url}/all`}
                                                    apiStateKey={getCityListAPI.key}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            name: "All"
                                                        }, ...response];

                                                        changeInputFn("reconciliation_search", "cityList", "", data)

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}

                                                    onChangeFn={(eventData) => {
                                                        const selectedCity = find(
                                                            get(formState, `reconciliation_search.cityList`, []),
                                                            (e) => e.id === eventData.value
                                                        );
                                                        changeInputFn("reconciliation_search", "selectedCity", "", selectedCity.name)
                                                    }}

                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Merchant"}
                                                    apiUrl={`${merchantAPI.url}/all`}
                                                    apiStateKey={merchantAPI.key}
                                                    inputName={"merchantId"}
                                                    placeHolderText={"Merchant"}
                                                    formGroupName={"reconciliation_search"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Delivery Type"}
                                                    inputName={"deliveryType"}
                                                    placeHolderText={"Delivery Type"}
                                                    formGroupName={"reconciliation_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...deliveryTypes
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Customer Name"
                                                    inputName={"customerName"}
                                                    formGroupName={"reconciliation_search"}
                                                    placeHolderText={"Customer Name"}
                                                    maxLength={100}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <InputBoxWithState
                                                    labelText="Order Number"
                                                    inputName={"channelOrderNo"}
                                                    formGroupName={"reconciliation_search"}
                                                    placeHolderText={"Order Number"}
                                                    maxLength={20}
                                                />
                                            </div>


                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Last Mile Driver"}
                                                    inputName={"deliveredBy"}
                                                    placeHolderText={"Last Mile Driver"}
                                                    formGroupName={"reconciliation_search"}
                                                    apiUrl={`${getUserDriverList.url}/all?category=LAST`}
                                                    apiStateKey={getUserDriverList.key}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.userId}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Status"}
                                                    inputName={"status"}
                                                    placeHolderText={"Status"}
                                                    formGroupName={"reconciliation_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...reconciliationReportStatuses
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Branch"}
                                                    inputName={"locationFinalFacilityId"}
                                                    placeHolderText={"Branch"}
                                                    formGroupName={"reconciliation_search"}
                                                    apiUrl={`${getAllFacilityAPI.url}`}
                                                    apiStateKey={`${getAllFacilityAPI.key}`}
                                                    keyName={"id"}
                                                    valueName={"name"}
                                                    onRebuildResponseFn={(data) => {
                                                        const temp = [{
                                                            id: "",
                                                            name: "All"
                                                        }];

                                                        data.forEach((value) => {
                                                            temp.push({
                                                                id: `${get(value, "locationId", "")}/${get(value, "location.name", "")}`,
                                                                name: get(value, "location.name", ""),
                                                            })

                                                            return temp
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": temp
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"COD Status"}
                                                    inputName={"codSettlementStatus"}
                                                    placeHolderText={"COD Status"}
                                                    formGroupName={"reconciliation_search"}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...codStatuses
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <RadioButtonWithState
                                                    isRequired={true}
                                                    labelText={"Report Type"}
                                                    inputName={"type"}
                                                    formGroupName={"reconciliation_search"}
                                                    dataList={[
                                                        {
                                                            id: "screen",
                                                            value: "View on Screen"
                                                        },
                                                        {
                                                            id: "csv",
                                                            value: "Export"
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={this.onGenerateFn}
                                                />

                                            </div>
                                        </div>
                                    </FormWrapper>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
    setPageLoader,
    changeInputFn,
    setApiResponse,
    setFlashMessage,
})(ReconciliationReport);
