import React, { Component } from "react";
import { groupBy, get } from "lodash";
import { Row, Col } from "reactstrap";

import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { facilityRateAPI, facilityWeightRateAPI, getDistrictListAPI, orderCSVUploadAPI } from "../../../../config/boda-boda/apiUrls.config";
import { connect } from "react-redux";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import UploadModalUI from "./weight-includes/UploadModalUI";
import { FormWrapper, InputButton, SelectBoxWithState } from "../../../../components/boda-boda/Forms";
import { fileGetAPIUrl } from "../../../../config/boda-boda/core.config";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { downloadCSV } from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setPageLoader, setFlashMessage } from "../../../../store/boda-boda/ui/actions";
import { setFormErrorFn } from "../../../../store/boda-boda/form/actions"
import { DownloadModalUI } from "./weight-includes/DownloadModal";
import { FilterUI } from "./weight-includes/FilterUI"

class Weight extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selected: false,
      districtId: "",
    }
  }


  buildResponse = (data) => {
    let city = groupBy(data, "cityId");
    let temp2 = [];

    Object.keys(city).forEach((values, index) => {
      temp2.push(
        {
          city: city[values][0].city.name,
          cityId: city[values][0].city.id,
          range1: {
            cost: city[values][0] ? city[values][0].cost : "-",
            price: city[values][0] ? city[values][0].price : "-",
          },
          range2: {
            cost: city[values][1] ? city[values][1].cost : "-",
            price: city[values][1] ? city[values][1].price : "-",
          },
          range3: {
            cost: city[values][2] ? city[values][2].cost : "-",
            price: city[values][2] ? city[values][2].price : "-",
          },

          range4: {
            cost: city[values][3] ? city[values][3].cost : "-",
            price: city[values][3] ? city[values][3].price : "-",
          },

          range5: {
            cost: city[values][4] ? city[values][4].cost : "-",
            price: city[values][4] ? city[values][4].price : "-",
          },

          range6: {
            cost: city[values][5] ? city[values][5].cost : "-",
            price: city[values][5] ? city[values][5].price : "-",
          },

          range7: {
            cost: city[values][6] ? city[values][6].cost : "-",
            price: city[values][6] ? city[values][6].price : "-",
          },

          range8: {
            cost: city[values][7] ? city[values][7].cost : "-",
            price: city[values][7] ? city[values][7].price : "-",
          },

          range9: {
            cost: city[values][8] ? city[values][8].cost : "-",
            price: city[values][8] ? city[values][8].price : "-",
          },

          range10: {
            cost: city[values][9] ? city[values][9].cost : "-",
            price: city[values][9] ? city[values][9].price : "-",
          },

          range11: {
            cost: city[values][10] ? city[values][10].cost : "-",
            price: city[values][10] ? city[values][10].price : "-",
          },
          range12: {
            cost: city[values][11] ? city[values][11].cost : "-",
            price: city[values][11] ? city[values][11].price : "-",
          },
          range13: {
            cost: city[values][12] ? city[values][12].cost : "-",
            price: city[values][12] ? city[values][12].price : "-",
          },
          range14: {
            cost: city[values][13] ? city[values][13].cost : "-",
            price: city[values][13] ? city[values][13].price : "-",
          },
          range15: {
            cost: city[values][14] ? city[values][14].cost : "-",
            price: city[values][14] ? city[values][14].price : "-",
          },
          range16: {
            cost: city[values][15] ? city[values][15].cost : "-",
            price: city[values][15] ? city[values][15].price : "-",
          },
          range17: {
            cost: city[values][16] ? city[values][16].cost : "-",
            price: city[values][16] ? city[values][16].price : "-",
          },
          range18: {
            cost: city[values][17] ? city[values][17].cost : "-",
            price: city[values][17] ? city[values][17].price : "-",
          },
          range19: {
            cost: city[values][18] ? city[values][18].cost : "-",
            price: city[values][18] ? city[values][18].price : "-",
          },
          range20: {
            cost: city[values][19] ? city[values][19].cost : "-",
            price: city[values][19] ? city[values][19].price : "-",
          },
          range21: {
            cost: city[values][20] ? city[values][20].cost : "-",
            price: city[values][20] ? city[values][20].price : "-",
          },
          range22: {
            cost: city[values][21] ? city[values][21].cost : "-",
            price: city[values][21] ? city[values][21].price : "-",
          },
        }
      )
    })

    return temp2;
  }

  // onClickDownloadFn = () => {
  //   let { match, setPageLoader, setFlashMessage } = this.props;

  //   setPageLoader(true);

  //   callApi(`${orderCSVUploadAPI.url}?page=1&limit=10&facilityId=${match.params.id}`)
  //     .headers(true)
  //     .method("get")
  //     .send()
  //     .then((result) => {
  //       if (get(result, "data.data", []).length > 0) {
  //         if (get(result, "data.data[0].status", "") === "PENDING") {
  //           window.open(`${fileGetAPIUrl}${get(result, "data.data[0].name", "")}`);
  //           setPageLoader(false);
  //         } else {
  //           callApi(`${orderCSVUploadAPI.url}/${get(result, "data.data[0].id", "")}/data`)
  //             .headers(true)
  //             .method("get")
  //             .send()
  //             .then((r) => {
  //               this.generateCSVData(get(r, "data.data", []), get(result, "data.data[0].name", ""));
  //               setPageLoader(false);
  //             })
  //         }
  //       } else {
  //         setPageLoader(false);
  //         setFlashMessage({
  //           status: true,
  //           message: "Records have not found",
  //           type: "danger",
  //         })
  //       }
  //     });
  // }

  validateFn = (formState, setFormErrorFn) => {
    // let { formState, setFormErrorFn } = this.props;
    const errors = [];

    if (get(formState, "actual_weight_rates.districtId", "") === "") {
      errors.push({
        "property": "districtId",
        "error": "The District field is required"
      })
      setFormErrorFn("actual_weight_rates", errors);
      return false;
    } else {
      setFormErrorFn("actual_weight_rates", []);
      return true;
    }
  }

  onClickView = (formState, setFormErrorFn) => {
    if (this.validateFn(formState, setFormErrorFn) === true) {
      this.setState({
        selected: true,
        districtId: get(this.props.formState, "actual_weight_rates.districtId", ""),
      })
    }
  }

  render() {
    let { match, setFormErrorFn, formState } = this.props;
    const initApiUrl = get(formState, `${facilityWeightRateAPI.key}_search.districtId`, '') === '' ? `&districtId=25`: ``;

    return (
      <React.Fragment>
        <FormWrapper
          setGroupName={"actual_weight_rates"}
          onDestroyUnsetFormObject={false}
          setFormObject={{
            districtId: "",
          }}
        >
          <div className="container-fluid">
            <Row className="align-items-center">
              <Col sm={6}>
                <div className="page-title-box">
                  <h4 className="font-size-18">Actual Weights</h4>
                  {/*<ol className="breadcrumb mb-0">*/}
                  {/*  <li className="breadcrumb-item">*/}
                  {/*    <Link to="#">Home</Link>*/}
                  {/*  </li>*/}
                  {/*  <li className="breadcrumb-item active">View Volumetric</li>*/}
                  {/*</ol>*/}
                </div>
              </Col>


              <Col sm={6}>
                <div className={"d-flex justify-content-end"}>

                  <BackBtnComponent
                    elementWrapperStyle={"mr-2"}
                    isGoBack={true}
                  />

                  <UploadModalUI
                    facilityId={match.params.id}
                  />

                  {/* <InputButton
                  btnText={"Download"}
                  startIcon={"fas fa-file-download"}
                  onClickBtnFn={() => this.generateCSVData()}
                /> */}
                  <DownloadModalUI
                    facilityId={match.params.id}
                  />

                </div>
              </Col>
            </Row>

            <div className={"row"}>
              <div className={"col-md-12"}>
                <div className={"card mb-1"}>
                  <div className={"card-body"}>
                    <dl className={"row mb-0"}>
                      <dd className={"col-sm-2"}>Delivery Center</dd>
                      <dt className={"col-sm-4"}>{match.params.name}</dt>
                      {/* <div className="col-md-6">
                        <SelectBoxWithState
                          labelText={"District"}
                          apiUrl={`${getDistrictListAPI.url}`}
                          apiStateKey={getDistrictListAPI.key}
                          inputName={"districtId"}
                          placeHolderText={"District"}
                          formGroupName={"actual_weight_rates"}
                          onRebuildResponseFn={(response) => {
                            const data = [];

                            response.forEach((value) => {
                              data.push({
                                id: `${value.id}`,
                                value: `${value.name}`,
                              });
                            });

                            return {
                              data: {
                                data: data,
                              },
                            };
                          }}
                        />
                        <InputButton
                          btnText={"View"}
                          onClickBtnFn={() => this.onClickView(formState, setFormErrorFn)}
                        />
                      </div> */}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            
            {/* {this.state.selected === false ? null : */}
              <Row>
                <CrudComponent
                  title={match.params.name + " Actual Weights Table"}
                  apiURL={`${facilityWeightRateAPI.url}/all?facilityId=${match.params.id}&status=ACTIVE${initApiUrl}`}//&districtId=get(this.props.formState)
                  filterFromComponent={FilterUI}
                  defaultFilterFormObject={{ districtId: "25" }}
                  dataTableStateKey={facilityWeightRateAPI.key}
                  isSetCreateAction={false}
                  isOpenFilterCollapse={true}
                  isSetAction={false}
                  isFilter={false}
                  isPagingShow={false}
                  onRebuildResponseFn={(response) => {
                    return {
                      data: {
                        data: this.buildResponse(response)
                      }
                    }
                  }}
                  onDestroyUnsetDatatableObject={false}
                  tableHeaderList={[
                    { displayName: "City", key: "city" },

                    { displayName: "Cost(0-0.5)", key: "range1.cost" },
                    { displayName: "Price(0-0.5)", key: "range1.price" },
    
                    { displayName: "Cost(0.5-1)", key: "range2.cost" },
    
                    { displayName: "Price(0.5-1)", key: "range2.price" },
    
                    { displayName: "Cost(1-2)", key: "range3.cost" },
    
                    { displayName: "Price(1-2)", key: "range3.price" },
    
                    { displayName: "Cost(2-3)", key: "range4.cost" },
    
                    { displayName: "Price(2-3)", key: "range4.price" },
    
                    { displayName: "Cost(3-4)", key: "range5.cost" },
    
                    { displayName: "Price(3-4)", key: "range5.price" },
    
                    { displayName: "Cost(4-5)", key: "range6.cost" },
                    { displayName: "Price(4-5)", key: "range6.price" },
                    { displayName: "Cost(5-6)", key: "range7.cost" },
                    { displayName: "Price(5-6)", key: "range7.price" },
    
                    { displayName: "Cost(6-7)", key: "range8.cost" },
                    { displayName: "Price(6-7)", key: "range8.price" },
                    { displayName: "Cost(7-8)", key: "range9.cost" },
                    { displayName: "Price(7-8)", key: "range9.price" },
                    { displayName: "Cost(8-9)", key: "range10.cost" },
                    { displayName: "Price(8-9)", key: "range10.price" },
                    { displayName: "Cost(9-10)", key: "range11.cost" },
                    { displayName: "Price(9-10)", key: "range11.price" },
                    { displayName: "Cost(10-11)", key: "range12.cost" },
                    { displayName: "Price(10-11)", key: "range12.price" },
                    { displayName: "Cost(11-12)", key: "range13.cost" },
                    { displayName: "Price(11-12)", key: "range13.price" },
                    { displayName: "Cost(12-13)", key: "range14.cost" },
                    { displayName: "Price(12-13)", key: "range14.price" },
                    { displayName: "Cost(13-14)", key: "range15.cost" },
                    { displayName: "Price(13-14)", key: "range15.price" },
                    { displayName: "Cost(14-15)", key: "range16.cost" },
                    { displayName: "Price(14-15))", key: "range16.price" },
                    { displayName: "Cost(15-16)", key: "range17.cost" },
                    { displayName: "Price(15-16)", key: "range17.price" },
                    { displayName: "Cost(16-17)", key: "range18.cost" },
                    { displayName: "Price(16-17)", key: "range18.price" },
                    { displayName: "Cost(17-18)", key: "range19.cost" },
                    { displayName: "Price(17-18)", key: "range19.price" },
                    { displayName: "Cost(18-19)", key: "range20.cost" },
                    { displayName: "Price(18-19)", key: "range20.price" },
                    { displayName: "Cost(19-20)", key: "range21.cost" },
                    { displayName: "Price(19-20)", key: "range21.price" },
                    { displayName: "Cost(20+)", key: "range22.cost" },
                    { displayName: "Price(20+)", key: "range22.price" },
                  ]}
                />
              </Row>
            {/* } */}
            
          </div>
        </FormWrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    formState: state.FormData,
    coreState: state.CoreState
  };
};

const WeightView = connect(mapStateToProps, {
  setPageLoader,
  setFlashMessage,
  setFormErrorFn
})(Weight);

export { WeightView };
