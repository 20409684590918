import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import validateMethod from "../../../helpers/boda-boda/validation";
import {setFormErrorFn} from "../form/actions";
import {getMerchantAPI, merchantBranchAPI, merchantBranchContactAPI} from "../../../config/boda-boda/apiUrls.config";
import {setFlashMessage, setPageLoader} from "../ui/actions";
import {callApi} from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import {defaultCountryCode} from "../../../config/boda-boda/core.config";
import parsePhoneNumber from "libphonenumber-js";

const emptyFn = (...para) => undefined;

function* createMerchantBranchFn(data) {
    let countryCode = get(data, 'payload.countryCode.id', "");

    if (countryCode === null) {
        countryCode = "";
    }

    countryCode = countryCode.toString().split("/");

    const validationResponse = yield call(() => validateMethod(
        get(data, 'payload', {}),
        {
            name: "Name",
            pickupPrice: "Pickup Price",
            facility: "Delivery Center",
            address: "Address",
            cityId: "City",
            fullName: "Contact Person",
            email: "Email",
            phone: "Phone",
            "countryCode.id": "Country Code",
        },
        {
            name: "required|min:4",
            pickupPrice: "required",
            facility: "required",
            address: "required",
            cityId: "required",
            fullName: "required",
            email: "required",
            phone: `required|phoneNumber:${get(countryCode, "[1]", defaultCountryCode)},countryCode.id`,
            "countryCode.id": "required",
        },
        {},
    ));

    if (validationResponse._status === false) {
        yield put(setFormErrorFn(`${merchantBranchAPI.key}_create`, validationResponse.errors));
    } else {
        yield put(setPageLoader(true));
        yield put(setFormErrorFn(`${merchantBranchAPI.key}_create`, []));

        let merchantBranchAPIRequestBody = {
            name: get(data, "payload.name", ""),
            merchant: {
                id: get(data, "payload.merchant", "")
            },
            facility: {
                id: get(data, "payload.facility", "")
            },
            location: {
                name: get(data, "payload.name", ""),
                address: get(data, "payload.address", ""),
                default: true,
                status: "ACTIVE",
                city: {
                    id: get(data, "payload.cityId", "")
                }
            },
            pickupPrice: Number(get(data, "payload.pickupPrice", ""))
        };

        let merchantBranchAPIRequest = null;

        if (get(data, "payload.branch", "") === "not_found"){
            merchantBranchAPIRequest = yield call(() => callApi(merchantBranchAPI.url).headers(true).method("post").body(merchantBranchAPIRequestBody).send());
        }else {
            merchantBranchAPIRequest = yield call(() => callApi(`${merchantBranchAPI.url}/${get(data, "payload.branch.id", "")}`).headers(true).method("put").body(merchantBranchAPIRequestBody).send());
        }

        if (merchantBranchAPIRequest._statue === true) {
            const merchantBranchContactAPIRequestBody = {
                name: get(data, "payload.fullName", ""),
                fullName: get(data, "payload.fullName", ""),
                phone: get(data, "payload.phone", ""),
                email: get(data, "payload.email", ""),
                merchantBranch: {
                    id: Number(get(merchantBranchAPIRequest, "data.data.id", ""))
                },
                notification: true
            }

            if (get(countryCode, "[1]") !== "OTHER") {
                const phoneNumber = parsePhoneNumber(get(data, "payload.phone", ""), get(countryCode, "[1]"));

                if (phoneNumber) {
                    merchantBranchContactAPIRequestBody["phone"] = phoneNumber.number;
                }
            }

            let merchantBranchContactAPIRequest = null


            if (get(data, "payload.contactId", null) === null){
                merchantBranchContactAPIRequest = yield call(() => callApi(`${merchantBranchContactAPI.url}`)
                    .headers(true)
                    .method("post")
                    .body(merchantBranchContactAPIRequestBody)
                    .send());
            } else {
                merchantBranchContactAPIRequest = yield call(() => callApi(`${merchantBranchContactAPI.url}/${get(data, "payload.contactId", "")}`)
                    .headers(true)
                    .method("put")
                    .body(merchantBranchContactAPIRequestBody)
                    .send());
            }

            if (merchantBranchContactAPIRequest._statue === true) {
                const merchantGETAPIRequest = yield call(() => callApi(`${getMerchantAPI.url}/${get(data, "payload.merchant", "")}`).headers(true).method("get").send());

                const merchantAPIRequestBody = {
                    name:  get(merchantGETAPIRequest, "data.data.name"),
                    code:  get(merchantGETAPIRequest, "data.data.code"),
                    registrationNo:  get(merchantGETAPIRequest, "data.data.registrationNo"),
                    category: {
                        id: get(merchantGETAPIRequest, "data.data.categoryId")
                    },
                    status: "ACTIVE",
                    type: get(merchantGETAPIRequest, "data.data.type"),
                    rating: get(merchantGETAPIRequest, "data.data.rating"),
                    website: get(merchantGETAPIRequest, "data.data.website")
                };

                const merchantAPIRequest = yield call(() => callApi(`${getMerchantAPI.url}/${get(data, "payload.merchant", "")}`).headers(true).method("put").body(merchantAPIRequestBody).send());

                if (merchantAPIRequest._statue === true) {
                    yield put(setFlashMessage({
                        status: true,
                        message: "Merchant has successfully created",
                        type: "success",
                    }));

                    get(data, "payload.historyFn.push", emptyFn)("/merchant");
                }
            }else {
                if (get(merchantBranchContactAPIRequest, "data.code", null) === 400){
                    yield put(setFormErrorFn(
                        `${merchantBranchAPI.key}_create`,
                        get(merchantBranchContactAPIRequest, "data.errors", [])
                    ))
                    yield put(setFlashMessage({
                        status: true,
                        message: get(merchantBranchContactAPIRequest, "data.errors[0].error", []),
                        type: "danger",
                    }));
                }
            }
        } else {
            if (get(merchantBranchAPIRequest, "data.code", null) === 400){
                yield put(setFormErrorFn(
                    `${merchantBranchAPI.key}_create`,
                    get(merchantBranchAPIRequest, "data.errors", [])
                ))
                yield put(setFlashMessage({
                    status: true,
                    message: get(merchantBranchAPIRequest, "data.errors[0].error", []),
                    type: "danger",
                }));
            }
        }
    }
    yield put(setPageLoader(false));
}

export {
    createMerchantBranchFn
}