import React, { Component } from "react";
import {
    FormWrapper,
    InputBoxWithState, InputButtonWithState, SelectBoxWithState
} from "../../../../../../components/boda-boda/Forms";
import {
    countryCodeAPI,
    getMerchantAPI,
    getMerchantBankAPI,
    getMerchantContactAPI
} from "../../../../../../config/boda-boda/apiUrls.config";
import { CustomLoader } from "../../../../../../components/boda-boda/CommonElements/BaseElements";
import { callApi } from "../../../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { get } from "lodash";
import { connect, useDispatch } from "react-redux";
import { createMerchantTrKey } from "../../../../../../store/boda-boda/transaction_request/actionTypes";
import { channelIdForTacking, contactTypes, notifications } from "../../../../../../config/boda-boda/db_config/db_status.config";

class ReMerchantProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            merchant: "fetching",
            merchantOperationContact: "fetching",
            merchantFinanceContact: "fetching",
            merchantBank: "fetching",
            isDisabled: true
        }
    }

    componentDidMount() {
        this.checkMerchant();
    }

    checkMerchant = async () => {
        let { merchantId } = this.props;

        if (merchantId !== undefined) {
            const merchantResponse = await callApi(`${getMerchantAPI.url}/${merchantId}`)
                .headers(true)
                .method("get")
                .send();

            if (merchantResponse._statue === true) {
                this.setState({
                    ...this.state,
                    merchant: get(merchantResponse, "data.data", {})
                })
            }

            const merchantBankResponse = await callApi(`${getMerchantBankAPI.url}?merchantId=${merchantId}&limit=1&page=1`)
                .headers(true)
                .method("get")
                .send();

            if (merchantBankResponse._statue === true) {
                if (get(merchantBankResponse, "data.data", []).length === 0) {
                    this.setState({
                        ...this.state,
                        merchantBank: "not_found"
                    })
                } else {
                    this.setState({
                        ...this.state,
                        merchantBank: get(merchantBankResponse, "data.data[0]", {})
                    })
                }
            }

            if (get(merchantResponse, "data.data.contacts", []).length === 0) {
                this.setState({
                    ...this.state,
                    merchantOperationContact: "not_found",
                    merchantFinanceContact: "not_found",
                })
            }
            if (get(merchantResponse, "data.data.contacts", []).length === 1) {
                if (get(merchantResponse, "data.data.contacts[0].departmentType", []) === contactTypes[0].id) {
                    this.setState({
                        ...this.state,
                        merchantFinanceContact: "not_found",
                    })
                } else {
                    this.setState({
                        ...this.state,
                        merchantOperationContact: "not_found",
                    })
                }
            }
            get(merchantResponse, "data.data.contacts", []).map(async (contact) => {
                const merchantContactId = get(contact, "id", '');
                const merchantContact = await callApi(`${getMerchantContactAPI.url}/${merchantContactId}`)
                    .headers(true)
                    .method("get")
                    .send();

                if (get(merchantContact, "data.data.departmentType", {}) === contactTypes[0].id) {
                    this.setState({
                        ...this.state,
                        merchantOperationContact: get(merchantContact, "data.data", {}),
                    })
                } else {
                    this.setState({
                        ...this.state,
                        merchantFinanceContact: get(merchantContact, "data.data", {}),
                    })
                }
            })

        } else {
            this.setState({
                ...this.state,
                merchant: "not_found",
                merchantOperationContact: "not_found",
                merchantFinanceContact: "not_found",
                merchantBank: "not_found"
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                {
                    (this.state.merchant === "fetching" || this.state.merchantOperationContact === "fetching" || this.state.merchantFinanceContact === "fetching" || this.state.merchantBank === "fetching") ? (
                        <center>
                            <CustomLoader />
                        </center>
                    ) : (
                        <FormWrapper
                            setGroupName={`${getMerchantAPI.key}_create`}
                            onDestroyUnsetFormObject={true}
                            setFormObject={{
                                name: get(this.state, "merchant.name", null),
                                code: get(this.state, "merchant.code", null),
                                registrationNo: get(this.state, "merchant.registrationNo", null),
                                website: get(this.state, "merchant.website", null),
                                operationContact: {
                                    contactName: get(this.state, "merchantOperationContact.name", null),
                                    fullName: get(this.state, "merchantOperationContact.fullName", null),
                                    phone: get(this.state, "merchantOperationContact.phone", null),
                                    email: get(this.state, "merchantOperationContact.email", null),
                                    notification: get(this.state, "merchantOperationContact.notification", null) === false ? "DISABLED" : "ENABLED",
                                    countryCode: {
                                        id: `${get(this.state, "merchantOperationContact.countryCode.id", "")}/${get(this.state, "merchantOperationContact.countryCode.code", "")}/${get(this.state, "merchantOperationContact.countryCode.prefix", "")}`
                                    },
                                    countryCodePrefix: get(this.state, `merchantOperationContact.countryCodePrefix`, ""),
                                },
                                financeContact: {
                                    contactName: get(this.state, "merchantFinanceContact.name", null),
                                    fullName: get(this.state, "merchantFinanceContact.fullName", null),
                                    phone: get(this.state, "merchantFinanceContact.phone", null),
                                    email: get(this.state, "merchantFinanceContact.email", null),
                                    notification: get(this.state, "merchantFinanceContact.notification", null) === false ? "DISABLED" : "ENABLED",
                                    countryCode: {
                                        id: `${get(this.state, "merchantFinanceContact.countryCode.id", "")}/${get(this.state, "merchantOperationContact.countryCode.code", "")}/${get(this.state, "merchantOperationContact.countryCode.prefix", "")}`
                                    },
                                    countryCodePrefix: get(this.state, `merchantFinanceContact.countryCodePrefix`, ""),
                                },
                                trackByChannelOrderNo: get(this.state, `merchant.trackByChannelOrderNo`, true),
                                trackByChannelOrderNoPrefix: get(this.state, `merchant.trackByChannelOrderNoPrefix`, ""),
                                isCustomerNotificationEnable: get(this.state, `merchant.isCustomerNotificationEnable`, true),
                                bank: get(this.state, `merchantBank.bank`, ""),
                                accountName: get(this.state, `merchantBank.accountName`, ""),
                                branch: get(this.state, `merchantBank.branch`, ""),
                                accountNumber: get(this.state, `merchantBank.accountNumber`, ""),
                            }}
                        >
                            <div className="container-fluid">
                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className={"card"}>
                                            <div className={"card-body"}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Merchant Name"
                                                            placeHolderText={"Merchant Name"}
                                                            inputName={"name"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Merchant Code"
                                                            placeHolderText={"Merchant Code"}
                                                            inputName={"code"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Business Reg. No."
                                                            placeHolderText={"Registration No"}
                                                            inputName={"registrationNo"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            labelText="Website"
                                                            placeHolderText={"Website"}
                                                            inputName={"website"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            labelText={"Use Channel Order No for Tracking Number"}
                                                            placeHolderText={"Use Channel Order No for Tracking Number"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                            inputName={"trackByChannelOrderNo"}
                                                            dataList={channelIdForTacking}
                                                            isRequired={true}
                                                        />
                                                    </div>

                                                    {get(this.props.formState, `${getMerchantAPI.key}_create.trackByChannelOrderNo`, false) === true ? (
                                                        <div className="col-md-6">
                                                            <InputBoxWithState
                                                                labelText="Tracking Number Prefix"
                                                                placeHolderText={"Tracking Number Prefix"}
                                                                inputName={"trackByChannelOrderNoPrefix"}
                                                                formGroupName={`${getMerchantAPI.key}_create`}
                                                                isRequired={true}
                                                            />
                                                        </div>
                                                    ) : null}

                                                    <div className="col-md-6">
                                                        <SelectBoxWithState
                                                            labelText={"Send Customer Notifications"}
                                                            placeHolderText={"Send Customer Notifications"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                            inputName={"isCustomerNotificationEnable"}
                                                            dataList={channelIdForTacking}
                                                            isRequired={true}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className={"card"}>
                                            <div className={"card-body"}>
                                                <div className={"card-title mb-4"}>Operations Contact Person</div>

                                                <div className={"row"}>
                                                    <div className={"col-md-6"}>
                                                        <InputBoxWithState
                                                            labelText={"Full Name"}
                                                            placeHolderText={"Full Name"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                            // inputName={"operationContact.fullName"}
                                                            isRequired={true}
                                                            inputStatePath={`${getMerchantAPI.key}_create.operationContact.fullName`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">

                                                        <div className={"row"}>
                                                            <div className={"col-md-3"}>
                                                                <SelectBoxWithState
                                                                    isRequired={true}
                                                                    labelText="Country Code"
                                                                    inputStatePath={`${getMerchantAPI.key}_create.operationContact.countryCode.id`}
                                                                    formGroupName={`${getMerchantAPI.key}_create`}
                                                                    apiUrl={`${countryCodeAPI.url}/all`}
                                                                    apiStateKey={countryCodeAPI.key}
                                                                    onRebuildResponseFn={(response) => {
                                                                        const data = [];

                                                                        response.forEach((value) => {
                                                                            data.push({
                                                                                id: `${value.id}/${value.code}/${value.prefix}`,
                                                                                value: value.code
                                                                            });
                                                                        });

                                                                        return {
                                                                            "data": {
                                                                                "data": data
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className={"col-md-9"}>
                                                                <InputBoxWithState
                                                                    labelText="Phone"
                                                                    // inputName={"phone"}
                                                                    placeHolderText={"+XXXXXXXXXXX"}
                                                                    formGroupName={`${getMerchantAPI.key}_create`}
                                                                    isRequired={true}
                                                                    inputStatePath={`${getMerchantAPI.key}_create.operationContact.phone`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-6"}>
                                                        <InputBoxWithState
                                                            labelText={"Email"}
                                                            placeHolderText={"Email"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                            // inputName={"email"}
                                                            isRequired={true}
                                                            maxLength={50}
                                                            inputStatePath={`${getMerchantAPI.key}_create.operationContact.email`}
                                                        />
                                                    </div>
                                                    <div className={"col-md-6"}>
                                                        <SelectBoxWithState
                                                            labelText={"Notifications"}
                                                            placeHolderText={"Notifications"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                            inputName={"notification"}
                                                            dataList={notifications}
                                                            isRequired={true}
                                                            inputStatePath={`${getMerchantAPI.key}_create.operationContact.notification`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className={"card"}>
                                            <div className={"card-body"}>
                                                <div className={"card-title mb-4"}>Finance Contact Person</div>

                                                <div className={"row"}>
                                                    <div className={"col-md-6"}>
                                                        <InputBoxWithState
                                                            labelText={"Full Name"}
                                                            placeHolderText={"Full Name"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                            // inputName={"fullName"}
                                                            isRequired={true}
                                                            inputStatePath={`${getMerchantAPI.key}_create.financeContact.fullName`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">

                                                        <div className={"row"}>
                                                            <div className={"col-md-3"}>
                                                                <SelectBoxWithState
                                                                    isRequired={true}
                                                                    labelText="Country Code"
                                                                    inputStatePath={`${getMerchantAPI.key}_create.financeContact.countryCode.id`}
                                                                    formGroupName={`${getMerchantAPI.key}_create`}
                                                                    apiUrl={`${countryCodeAPI.url}/all`}
                                                                    apiStateKey={countryCodeAPI.key}
                                                                    onRebuildResponseFn={(response) => {
                                                                        const data = [];

                                                                        response.forEach((value) => {
                                                                            data.push({
                                                                                id: `${value.id}/${value.code}/${value.prefix}`,
                                                                                value: value.code
                                                                            });
                                                                        });

                                                                        return {
                                                                            "data": {
                                                                                "data": data
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className={"col-md-9"}>
                                                                <InputBoxWithState
                                                                    labelText="Phone"
                                                                    // inputName={"phone"}
                                                                    placeHolderText={"+XXXXXXXXXXX"}
                                                                    formGroupName={`${getMerchantAPI.key}_create`}
                                                                    isRequired={true}
                                                                    inputStatePath={`${getMerchantAPI.key}_create.financeContact.phone`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-6"}>
                                                        <InputBoxWithState
                                                            labelText={"Email"}
                                                            placeHolderText={"Email"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                            // inputName={"email"}
                                                            isRequired={true}
                                                            maxLength={50}
                                                            inputStatePath={`${getMerchantAPI.key}_create.financeContact.email`}
                                                        />
                                                    </div>
                                                    <div className={"col-md-6"}>
                                                        <SelectBoxWithState
                                                            labelText={"Notifications"}
                                                            placeHolderText={"Notifications"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                            // inputName={"notification"}
                                                            dataList={notifications}
                                                            isRequired={true}
                                                            inputStatePath={`${getMerchantAPI.key}_create.financeContact.notification`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className={"card"}>
                                            <div className={"card-body"}>
                                                <div className={"card-title mb-4"}>Bank Details</div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Bank"
                                                            placeHolderText={"Bank"}
                                                            inputName={"bank"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Account Name"
                                                            placeHolderText={"Account Name"}
                                                            inputName={"accountName"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Branch"
                                                            placeHolderText={"Branch"}
                                                            inputName={"branch"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <InputBoxWithState
                                                            isRequired={true}
                                                            labelText="Account Number"
                                                            placeHolderText={"Account Number"}
                                                            inputName={"accountNumber"}
                                                            formGroupName={`${getMerchantAPI.key}_create`}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        <div className={"d-flex justify-content-end"}>
                                            <CreateBtn
                                                onNextFn={this.props.onNextFn}
                                                merchant={get(this.state, "merchant", "")}
                                                merchantOperationContact={get(this.state, "merchantOperationContact", "")}
                                                merchantFinanceContact={get(this.state, "merchantFinanceContact", "")}
                                                merchantBank={get(this.state, "merchantBank", "")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormWrapper>
                    )
                }
            </React.Fragment>
        )
    }
}

const CreateBtn = (props) => {
    const dispatch = useDispatch();

    return (
        <InputButtonWithState
            elementWrapperStyle={"mr-2"}
            btnText={"Next"}
            formGroupName={`${getMerchantAPI.key}_create`}
            onClickBtnFn={(dataObject) => {
                dispatch({
                    type: createMerchantTrKey,
                    payload: {
                        ...dataObject.formObject,
                        merchant: props.merchant,
                        merchantOperationContact: props.merchantOperationContact,
                        merchantFinanceContact: props.merchantFinanceContact,
                        merchantBank: props.merchantBank,
                        onNextFn: props.onNextFn
                    }
                })
            }}
        />
    )
}


const mapStateToProps = state => {
    return {
        formState: state.FormData
    };
};

export default connect(mapStateToProps, null)(ReMerchantProfile);
