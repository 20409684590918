import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { FormWrapper } from "../../../../components/boda-boda/Forms";
import { getManifestListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { CrudComponent } from "../../../../components/boda-boda/CRUD/CrudComponent";


const PickRequestViewModalUI = (props) => {
    let { row } = props;

    const formState = useSelector(state => state.FormData);

    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`${getManifestListAPI.key}_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${row.id}`}
                onRebuildResponseFn={(response) => {
                    return {
                        id: response.id,
                        status: response.status,
                        locationPick: response.locationPick,
                        locationDrop: response.locationDrop,
                        notes: response.notes,
                        pickAt: response.pickAt,
                        packages: response.packages,
                        merchant: response.merchant.name,
                        code: response.code,
                        publishedPackages: response.publishedPackages,
                        "_onLoad": false,
                    }
                }}
            >
                <div className="row">
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-3">Package Details</h4>

                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-4"}>Manifest Code</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Drop Location</dd>
                                            <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                            <dd className={"col-sm-4"}>Pick Up Date</dd>
                                            <dt className={"col-sm-8"}>
                                                {dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""), "YY-mm-dd")}
                                            </dt>
                                        </dl>
                                    </div>

                                    <div className={"col-md-6"}>
                                        <dl className={"row"}>
                                            <dd className={"col-sm-5"}>Merchant</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.merchant`, "")}</dt>

                                            <dd className={"col-sm-5"}>Status</dd>
                                            <dt className={"col-sm-7"}>
                                                {
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH" ? "PENDING" :
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "COMPLETED" ? "PICKUP COMPLETED" :
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "IN_TRANSIT_FIRST" ? "PICKED UP":
                                                    get(formState, `${getManifestListAPI.key}_view.status`, "")
                                                }
                                            </dt>
                                            <dd className={"col-sm-5"}>Received Packages Count</dd>
                                            {/* <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.packages.length`, "0")}</dt> */}
                                            <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.publishedPackages`, "[]").length}</dt>
                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <CrudComponent
                        title="Selected Packages"
                        apiURL={`${getManifestListAPI.url}/${row.id}/published-packages?page=1&limit=20`}
                        dataTableStateKey={`${getManifestListAPI.key}_pickRequestModal`}
                        isFilter={false}
                        isSetAction={false}
                        isSetCreateAction={false}
                        tableHeaderList={[
                            { displayName: "Article No", key: "articleNo" },
                            { displayName: "Customer Name", key: "order.name" },
                            { displayName: "Delivery Type", key: "deliveryType" },
                            { displayName: "Payment Method", key: "order.paymentMethod" },
                            { displayName: "Package Category", key: "category.name" },
                            {
                                displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                    if (rawData.status === "IN_TRANSIT_FIRST") {
                                        return "Package Picked up";
                                    } else {
                                        return rawData.status
                                    }
                                }
                            },
                        ]}
                    />
                </div>

                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Notes</h4>

                                <div className={"row"}>
                                    <div className={"col-md-12"}>
                                        {
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []) != null &&
                                            get(formState, `${getManifestListAPI.key}_view.notes`, []).length > 0 ? (
                                                <ul style={{ paddingLeft: "17px" }}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.notes`, []).map((element, index) => {
                                                            return (
                                                                <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            ) : (
                                                    <div>None</div>
                                                )
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </FormWrapper>
        </div>
    );
};

export default PickRequestViewModalUI;
