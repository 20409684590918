import React, { Component, useEffect, Fragment } from "react";
import { FormWrapper, InputButton } from "../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { get } from "lodash";
import { getManifestListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { useSelector } from "react-redux";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import JsBarcode from "jsbarcode";
import {printModalHelperFn} from "../../../../helpers/boda-boda/common-helpers/common.helpers";

class Manifest extends Component {
    constructor(props) {
        super(props);
        this.state = { isShow: false };
    }

    render() {

        const onClickFn = () => {
            this.setState({ isShow: true })
        };

        return (
            <React.Fragment>
                <InputButton
                    btnText={"Print"}
                    onClickBtnFn={() => onClickFn()}
                    startIcon={"mdi mdi-printer"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Manifest"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<MerchantPrint row={this.props.package} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintManifestBtn />
                    }
                />
            </React.Fragment>
        );
    }
}

const MerchantPrint = (props) => {
    const formState = useSelector(state => state.FormData);

    useEffect(() => {
        if (get(formState, `${getManifestListAPI.key}_print._onLoad`, true) === false) {
            JsBarcode("#manifestLabelBarcode", get(formState, `${getManifestListAPI.key}_print.code`, ""), {
                width: 1,
                height: 40,
                margin: 0,
                fontSize: 15
            })

            JsBarcode(".articleID").init();
        }
    }, [
        get(formState, `${getManifestListAPI.key}_print._onLoad`, true)
    ])

    return (
        <div id={"print_preview"}>
            <div className={"container-fluid"}>
                <FormWrapper
                    setGroupName={`${getManifestListAPI.key}_print`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getManifestListAPI.url}/${get(props, `row.id`, "")}`}
                    onRebuildResponseFn={(response) => {
                        console.log(response,"response")
                        return {
                            id: get(response, "id", ""),
                            status: get(response, "status", ""),
                            packages: get(response, "packages", ""),
                            locationDrop: get(response, "locationDrop", ""),
                            locationPick: get(response, "locationPick", ""),
                            notes: get(response, "notes", ""),
                            merchant: get(response, "merchant", ""),
                            driver: get(response, `driver`, ""),
                            pickAt: get(response, "pickAt", ""),
                            orderCount: get(response, "merchant.orderCount", ""),
                            code: get(response, "code", ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Merchant Name</dd>
                                <dt className={"col-sm-7"}>{get(formState,`${getManifestListAPI.key}_print.merchant.name`, "")}</dt>

                                <dd className={"col-sm-5"}>Merchant Code</dd>
                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_print.merchant.code`, "")}</dt>

                                <dd className={"col-sm-5"}>Manifest Code</dd>
                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_print.code`, "")}</dt>

                                <dd className={"col-sm-5"}></dd>
                                <dt className={"col-sm-7"}><img id="manifestLabelBarcode" /></dt>


                                {/*<dd className={"col-sm-5"}>Manifest Special Notes</dd>*/}
                                {/*<dt className={"col-sm-7"}>{get(props, `row.notes`, "-")}</dt>*/}
                            </dl>

                            <div className={"row"}>
                                <div className={"col-sm-12"}>

                                </div>
                            </div>
                        </div>

                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Pick Up Details</dd>
                                <dt className={"col-sm-7"}>{get(props, `row.locationPick.address`, "")}</dt>

                                <dd className={"col-sm-5"}>Pick Up Date</dd>
                                <dt className={"col-sm-7"}>{dateObjectToString(get(props, `row.pickAt`, ""), "YY-mm-dd")}</dt>

                                <dd className={"col-sm-5"}>Driver Name</dd>
                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_print.driver.name`, "-")}</dt>
                            </dl>
                        </div>
                    </div>

                    <div className={"row"}>
                        <Fragment>
                            <div className="table-rep-plugin">
                                <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                >
                                    <table id="tech-companies-1" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Article ID</th>
                                                <th>Consignment No. Name & Address</th>
                                                <th>Payment Method</th>
                                                <th>Signature Required</th>
                                                <th>ID Verification Required</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                get(formState, `${getManifestListAPI.key}_print.packages`, []).map((bodyData, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <svg
                                                                    class="articleID"
                                                                    jsbarcode-value={get(bodyData, "articleNo", "")}
                                                                    jsbarcode-width="1"
                                                                    jsbarcode-height="25"
                                                                    jsbarcode-margin="0"
                                                                />
                                                            </td>
                                                            <td>
                                                                {get(bodyData, "order.consignmentNo", "")}<br />
                                                                {get(bodyData, "order.shippingLocation.name", "")}<br />
                                                                {get(bodyData, "order.shippingLocation.address", "")}
                                                            </td>
                                                            <td>{get(bodyData, "order.paymentMethod", "")}</td>
                                                            <td>
                                                                {
                                                                    (get(bodyData, "order.signatureRequired", "") === true) ? (
                                                                        "Yes"
                                                                    ) : (
                                                                            "No"
                                                                        )
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    (get(bodyData, "order.idVerificationRequired", "") === true) ? (
                                                                        "Yes"
                                                                    ) : (
                                                                            "No"
                                                                        )
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </FormWrapper>
            </div>
        </div>
    )
}

const PrintManifestBtn = () => {
    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => printModalHelperFn("print_preview")}
        />
    )
}

export {
    Manifest,
    MerchantPrint,
    PrintManifestBtn
}