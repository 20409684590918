import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { get } from "lodash";
import { connect } from "react-redux";

import {
    InputButton,
    FormWrapper,
    DatePickerBoxWithState,
    SelectBoxWithState,
    RadioButtonWithState
} from "../../../../components/boda-boda/Forms";
import {
    isEmptyValue,
    fromObjectToQueryString,
    downloadCSV,
    roundValue,
    convertToCsvValue
} from "../../../../helpers/boda-boda/common-helpers/common.helpers";
import { setFormErrorFn } from "../../../../store/boda-boda/form/actions";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { cashOnDeliveryReportAPI, getAllFacilityAPI, getMerchantBranchAllAPI, getUserDriverList, merchantAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { setPageLoader } from "../../../../store/boda-boda/ui/actions";
import { setFlashMessage } from '../../../../store/boda-boda/ui/actions'
import { codStatuses } from "../../../../config/boda-boda/db_config/db_status.config";


class CashCardOnDelivery extends Component {
    onGenerateFn = () => {
        let { formState, history } = this.props;

        if (this.validateFn() === true) {
            let dateTo = new Date(get(formState, "cash_card_delivery_report.dateTo"));
            dateTo = new Date(dateTo.setDate(dateTo.getDate() + 1));
            dateTo = new Date(dateTo.setSeconds(dateTo.getSeconds() - 1));

            const queryObj = {
                dateFrom: get(formState, "cash_card_delivery_report.dateFrom").toISOString(),
                dateTo: dateTo.toISOString(),
                paymentMethod: get(formState, "cash_card_delivery_report.paymentMethod"),
                merchantId: get(formState, "cash_card_delivery_report.merchantId").split("/")[0],
                driverId: get(formState, "cash_card_delivery_report.driverId").split("/")[0],
                codSettlementStatus: get(formState, "cash_card_delivery_report.codSettlementStatus"),
                locationFinalFacilityId: get(formState, "cash_card_delivery_report.locationFinalFacilityId").split("/")[0],
            }

            if (get(formState, "cash_card_delivery_report.type", "screen") === "screen") {
                history.push("/reports/cash-or-card-on-delivery-report/view");
            } else {
                this.getAPIData(fromObjectToQueryString(queryObj));
            }
        }
    }

    validateFn = () => {
        let { formState, setFormErrorFn } = this.props;
        const errors = [];

        if (isEmptyValue(get(formState, "cash_card_delivery_report.dateFrom", ""))) {
            errors.push({
                "property": "dateFrom",
                "error": "The Delivery Transaction Date From field is required"
            })
        }

        if (isEmptyValue(get(formState, "cash_card_delivery_report.dateTo", ""))) {
            errors.push({
                "property": "dateTo",
                "error": "The Delivery Transaction Date To field is required"
            })
        }

        if (errors.length === 0) {
            if (new Date(get(formState, "cash_card_delivery_report.dateFrom", "")) > new Date(get(formState, "cash_card_delivery_report.dateTo", ""))) {
                setFormErrorFn("cash_card_delivery_report", [{
                    "property": "dateTo",
                    "error": "The Delivery Transaction Date To field should not be less than the Delivery Transaction Date From"
                }]);

                return false;
            } else {
                setFormErrorFn("cash_card_delivery_report", []);
                return true;
            }
        } else {
            setFormErrorFn("cash_card_delivery_report", errors);
            return false;
        }
    }

    getAPIData = (queryString) => {
        let { setPageLoader, setFlashMessage } = this.props;

        setPageLoader(true);

        callApi(`${cashOnDeliveryReportAPI.url}?${queryString}&isJsonFile=true`)
            .method("get")
            .headers(true)
            .send()
            .then(async (result) => {
                if (get(result, "_statue", "") === false) {
                    if (get(result, "data.statusCode", 0) === 403) {
                        setFlashMessage({
                            status: true,
                            message: "Permission denied to access",
                            type: "danger",
                        });
                    } else {
                        setFlashMessage({
                            status: true,
                            message: "Something went wrong",
                            type: "danger",
                        });
                    }
                    setPageLoader(false);
                } else {
                    if (result?.data?.data?.isJsonFile) {
                        const response = await fetch(result?.data?.data?.url);

                        if (!response.ok) {
                            throw new Error('Wrong URL provided');
                        }

                        result.data = await response.json();
                    }
                    this.generateCSVData(get(result, "data.data", []), get(result, "data.meta.totalsRow", {}));
                    setPageLoader(false);
                }
            })
            .catch((error) => {
                setPageLoader(false);
            })
    }

    generateCSVData = (data = [], totalData) => {
        let { formState } = this.props;

        const reportName = ["Cash / Card on Delivery Report"];
        const dateRange = [`From ${dateObjectToString(get(formState, "cash_card_delivery_report.dateFrom"), "dd/mm/YY")} to ${dateObjectToString(get(formState, "cash_card_delivery_report.dateTo"), "dd/mm/YY")}`];
        const merchant = [`"Merchant: ${convertToCsvValue(get(formState, "cash_card_delivery_report.merchantId", "") === "" ? "All" : get(formState, "cash_card_delivery_report.merchantId", "").split("/")[1])}"`];
        const paymentType = [`"Payment Type: ${convertToCsvValue(get(formState, "cash_card_delivery_report.paymentMethod", "") === "" ? "All" : get(formState, "cash_card_delivery_report.paymentMethod", ""))}"`];
        const deliveryDriver = [`"Delivery Driver: ${convertToCsvValue(get(formState, "cash_card_delivery_report.driverId", "") === "" ? "All" : get(formState, "cash_card_delivery_report.driverId", "").split("/")[1])}"`];
        const branch = [`"Destination Delivery Center: ${convertToCsvValue(get(formState, "cash_card_delivery_report.codSettlementStatus", "") === "" ? "All" : get(formState, "cash_card_delivery_report.codSettlementStatus", ""))}"`];
        const codStatus = [`"COD Status: ${convertToCsvValue(get(formState, "cash_card_delivery_report.locationFinalFacilityId", "") === "" ? "All" : get(formState, "cash_card_delivery_report.locationFinalFacilityId", "").split("/")[1])}"`];
        const headers = ["Transaction Date & Time", "Consignment ID", "Tracking No", "Branch", "Merchant Code", "No of Articles", "Delivery Driver", "Last Mile delivery", "Payment Type",
        "COD Status","Amount",  "Cash on Delivery Fee", "Card on Delivery Fee"
        ];

        const dataList = [];

        data.forEach((value) => {
            const temp = [];

            temp.push(dateObjectToString(get(value, "deliveredAt", new Date()), "YY-mm-dd HH:MM:II"));
            temp.push(`"${convertToCsvValue(get(value, "consignmentNo", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "trackingNumber", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "packages[0].locationFinalFacility.facilities[0].name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "merchant.code", "-"))}"`);
            temp.push(get(value, "_count.packages", "-"));
            temp.push(`"${convertToCsvValue(get(value, "courier.staffs[0].name", "-"))}"`);
            temp.push(`"${convertToCsvValue(get(value, "shippingLocation.name", "-"))}"`);
            temp.push(get(value, "paymentMethod", "-"));
            temp.push(`"${convertToCsvValue(get(value, "paymentMethod") === "CASH_ON_DELIVERY" ?get(value, "codSettlementStatus", "-"): "-")}"`);
            temp.push(get(value, "amount", "") === null ? 0 : get(value, "amount", ""));
            if (get(value, "paymentMethod", "") === "CASH_ON_DELIVERY") {
                temp.push(get(value, "cashDeliveryPrice", "0"));
            } else {
                temp.push(0);
            }

            if (get(value, "paymentMethod", "") === "CARD_ON_DELIVERY") {
                temp.push(get(value, "cardDeliveryPrice", "0"));
            } else {
                temp.push(0);
            }

            dataList.push(temp);
        });

        const finalDataSet = [reportName, [""], dateRange, merchant, paymentType, deliveryDriver, branch, codStatus, [""], [""], headers];

        dataList.forEach((value) => {
            finalDataSet.push(value);
        });

        finalDataSet.push([
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Total",
            roundValue(get(totalData, "amount", "")),
            roundValue(get(totalData, "cashDeliveryPrice", "")),
            roundValue(get(totalData, "cardDeliveryPrice", ""))
        ]);

        const csvDataContent = finalDataSet.map(e => e.join(",")).join("\n");
        const fileName = `Cash / Card on Delivery Report-${Date.now()}.csv`;
        downloadCSV(csvDataContent, fileName);
    }

    render() {
        let { formState } = this.props;
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Cash / Card on Delivery Report</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Cash / Card on Delivery Report</li>
                                </ol>
                            </div>
                        </Col>
                    </Row>

                    <div className={"row"}>
                        <div className={"col-md-12"}>

                            <div className={"card"} style={{ marginBottom: "120px" }}>
                                <div className={"card-body"}>

                                    <FormWrapper
                                        setGroupName={"cash_card_delivery_report"}
                                        onDestroyUnsetFormObject={false}
                                        setFormObject={{
                                            dateFrom: null,
                                            dateTo: null,
                                            merchantId: "",
                                            paymentMethod: "",
                                            type: "screen",
                                            driverId: "",
                                            codSettlementStatus: "",
                                            locationFinalFacilityId: "",
                                        }}
                                    >
                                        <div className={"row"}>
                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Delivery Transaction Date From"
                                                    inputName={"dateFrom"}
                                                    formGroupName={"cash_card_delivery_report"}
                                                    placeholderText={"Delivery Transaction Date From"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <DatePickerBoxWithState
                                                    isRequired={true}
                                                    labelText="Delivery Transaction Date To"
                                                    inputName={"dateTo"}
                                                    formGroupName={"cash_card_delivery_report"}
                                                    placeholderText={"Delivery Transaction Date To"}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Merchant"}
                                                    apiUrl={`${merchantAPI.url}/all`}
                                                    apiStateKey={merchantAPI.key}
                                                    inputName={"merchantId"}
                                                    placeHolderText={"Merchant"}
                                                    formGroupName={"cash_card_delivery_report"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Payment Type"}
                                                    inputName={"paymentMethod"}
                                                    placeHolderText={"Payment Type"}
                                                    formGroupName={"cash_card_delivery_report"}
                                                    isDisabled={get(formState, "cash_card_delivery_report.codSettlementStatus")}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        {
                                                            id: "CASH_ON_DELIVERY",
                                                            value: "CASH ON DELIVERY",
                                                        },
                                                        {
                                                            id: "CARD_ON_DELIVERY",
                                                            value: "CARD ON DELIVERY",
                                                        },
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Destination Delivery Center"}
                                                    apiUrl={`${getAllFacilityAPI.url}`}
                                                    apiStateKey={getAllFacilityAPI.key}
                                                    inputName={"locationFinalFacilityId"}
                                                    placeHolderText={"Destination Delivery Center"}
                                                    formGroupName={"cash_card_delivery_report"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.locationId}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"COD Status"}
                                                    inputName={"codSettlementStatus"}
                                                    placeHolderText={"COD Status"}
                                                    formGroupName={"cash_card_delivery_report"}
                                                    isDisabled={get(formState, "cash_card_delivery_report.paymentMethod") && get(formState, "cash_card_delivery_report.paymentMethod") !== 'CASH_ON_DELIVERY'}
                                                    dataList={[
                                                        {
                                                            id: "",
                                                            value: "All"
                                                        },
                                                        ...codStatuses
                                                    ]}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <SelectBoxWithState
                                                    labelText={"Delivery Driver"}
                                                    apiUrl={`${getUserDriverList.url}/all`}
                                                    apiStateKey={getUserDriverList.key}
                                                    inputName={"driverId"}
                                                    placeHolderText={"Delivery Driver"}
                                                    formGroupName={"cash_card_delivery_report"}
                                                    onRebuildResponseFn={(response) => {
                                                        const data = [{
                                                            id: "",
                                                            value: "All"
                                                        }];

                                                        response.forEach((value) => {
                                                            data.push({
                                                                id: `${value.id}/${value.name}`,
                                                                value: value.name
                                                            });
                                                        });

                                                        return {
                                                            "data": {
                                                                "data": data
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <RadioButtonWithState
                                                    isRequired={true}
                                                    labelText={"Report Type"}
                                                    inputName={"type"}
                                                    formGroupName={"cash_card_delivery_report"}
                                                    dataList={[
                                                        {
                                                            id: "screen",
                                                            value: "View on Screen"
                                                        },
                                                        {
                                                            id: "csv",
                                                            value: "Export"
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>

                                        <div className={"row"}>
                                            <div className={"col-md-12"}>

                                                <InputButton
                                                    btnText={"Generate"}
                                                    startIcon={"fas fa-spinner"}
                                                    onClickBtnFn={this.onGenerateFn}
                                                />

                                            </div>
                                        </div>
                                    </FormWrapper>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        formState: state.FormData,
        authState: state.AuthState,
    };
};

export default connect(mapStateToProps, {
    setFormErrorFn,
    setPageLoader,
    setFlashMessage,
})(CashCardOnDelivery);
