import React, { Component, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import JsBarcode from "jsbarcode";
import { get } from "lodash";

import { FormWrapper, InputButton } from "../../../../components/boda-boda/Forms";
import { modalTypes, ModalUI } from "../../../../components/boda-boda/Modal/ModalUI";
import { getManifestListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import {printModalHelperFn} from "../../../../helpers/boda-boda/common-helpers/common.helpers";


class ManifestPrintModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isShow: false
        };
    }

    onClickFn = () => {
        this.setState({ isShow: true })
    };

    render() {
        return (
            <React.Fragment>
                <InputButton
                    btnText={"Print"}
                    onClickBtnFn={() => this.onClickFn()}
                    startIcon={"mdi mdi-printer"}
                />

                <ModalUI
                    isOpen={this.state.isShow}
                    modalTitle={"Manifest"}
                    modalType={modalTypes.large}
                    modalBodyComponent={<ManifestPrintBody row={this.props.manifest} />}
                    showHideToggleFn={() => this.setState({ isShow: false })}
                    modalFooterComponent={
                        <PrintManifestBtn />
                    }
                />
            </React.Fragment>
        );
    }
}

const ManifestPrintBody = (props) => {
    const formState = useSelector(state => state.FormData);

    useEffect(() => {
        if (get(formState, `${getManifestListAPI.key}_print._onLoad`, true) === false) {
            JsBarcode("#manifestLabelBarcode", get(formState, `${getManifestListAPI.key}_print.code`, ""), {
                width: 1,
                height: 40,
                margin: 0,
                fontSize: 15
            })

            JsBarcode(".articleID").init();
        }
    }, [
        get(formState, `${getManifestListAPI.key}_print._onLoad`, true)
    ])

    return (
        <div id={"print_preview"}>
            <div className={"runSheetLinePrintWrapper"}>
                <FormWrapper
                    setGroupName={`${getManifestListAPI.key}_print`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getManifestListAPI.url}/${get(props, `row.id`, "")}/published-packages/all`}
                    onRebuildResponseFn={(response) => {
                        return {
                            code: get(props, `row.code`, ""),
                            merchant: get(props, `row.merchantObj`, ""),
                            driver: get(props, `row.driver`, ""),
                            packages: response,
                            locationPick: get(props, `row.locationPick`, ""),
                            pickAt: get(props, `row.pickAt`, ""),
                            "_onLoad": false,
                        }
                    }}
                >
                    <div className={"row"}>
                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Merchant Name</dd>
                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_print.merchant.name`, "")}</dt>

                                <dd className={"col-sm-5"}>Merchant Code</dd>
                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_print.merchant.code`, "")}</dt>

                                <dd className={"col-sm-5"}>Manifest Code</dd>
                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_print.code`, "")}</dt>

                                <dd className={"col-sm-5"}></dd>
                                <dt className={"col-sm-7"}><img id="manifestLabelBarcode" /></dt>

                                {/*<dd className={"col-sm-5"}>Manifest Special Notes</dd>*/}
                                {/*<dt className={"col-sm-7"}>{get(props, `row.notes`, "-")}</dt>*/}
                            </dl>
                        </div>

                        <div className={"col-sm-6"}>
                            <dl className={"row"}>
                                <dd className={"col-sm-5"}>Pick Up Details</dd>
                                <dt className={"col-sm-7"}>
                                    {
                                        get(formState, `${getManifestListAPI.key}_print.locationPick.address`, "")
                                    }
                                    ,
                                    {
                                        get(formState, `${getManifestListAPI.key}_print.locationPick.city.name`, "")
                                    }
                                </dt>

                                <dd className={"col-sm-5"}>Pick Up Date</dd>
                                <dt className={"col-sm-7"}>{dateObjectToString(get(formState, `${getManifestListAPI.key}_print.pickAt`, ""), "YY-mm-dd")}</dt>

                                <dd className={"col-sm-5"}>Driver Name</dd>
                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_print.driver.name`, "-")}</dt>
                            </dl>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-sm-12"}>
                            <div className="table-rep-plugin">
                                <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                >
                                    <table id="tech-companies-1" className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>Article ID</th>
                                            <th>Consignment No. Name & Address</th>
                                            <th>Payment Method</th>
                                            <th>Signature Required</th>
                                            <th>ID Verification Required</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            get(formState, `${getManifestListAPI.key}_print.packages`, []).length === 0 ? (
                                                <tr>
                                                    <td colSpan={5} className={"text-center"}>No packages found</td>
                                                </tr>
                                            ) : (
                                                get(formState, `${getManifestListAPI.key}_print.packages`, []).map((bodyData, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <svg
                                                                    class="articleID"
                                                                    jsbarcode-value={get(bodyData, "articleNo", "")}
                                                                    jsbarcode-width="1"
                                                                    jsbarcode-height="35"
                                                                    jsbarcode-margin="0"
                                                                    jsbarcode-fontsize="15"

                                                                />
                                                            </td>
                                                            <td>
                                                                {get(bodyData, "order.consignmentNo", "")}<br />
                                                                {get(bodyData, "order.name", "")}<br />
                                                                {get(bodyData, "order.shippingLocation.name", "")}<br />
                                                                {get(bodyData, "order.shippingLocation.address", "")}
                                                            </td>
                                                            <td>{get(bodyData, "order.paymentMethod", "")}</td>
                                                            <td>
                                                                {
                                                                    (get(bodyData, "order.signatureRequired", "") === true) ? (
                                                                        "Yes"
                                                                    ) : (
                                                                        "No"
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    (get(bodyData, "order.idVerificationRequired", "") === true) ? (
                                                                        "Yes"
                                                                    ) : (
                                                                        "No"
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        </div>
    )
}

const PrintManifestBtn = () => {
    return (
        <InputButton
            btnText={"Print"}
            onClickBtnFn={() => printModalHelperFn("print_preview")}
        />
    )
}

export {
    ManifestPrintModal,
    ManifestPrintBody,
    PrintManifestBtn
}
