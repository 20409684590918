import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { get } from "lodash";

import { FormWrapper, InputButton, SubmitButton } from "../../../../components/boda-boda/Forms";
import { getManifestListAPI, getPackageListAPI } from "../../../../config/boda-boda/apiUrls.config";
import { BackBtnComponent } from "../../../../components/boda-boda/CommonElements/BaseElements";
import { dateObjectToString } from "../../../../helpers/boda-boda/common-helpers/dateTime.helpers";
import { CrudComponent, actionBtnTypes } from "../../../../components/boda-boda/CRUD/CrudComponent";
import { apiResponseStoringType } from "../../../../config/boda-boda/apiResponseKey";
import { UIModal } from "./AcceptRejectUI";
import { ManifestPrintModal } from "./PrintUI";
import { callApi } from "../../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { BulkShippingLabelPrint } from "../../packages/includes/BulkShippingLabelPrint";


const PickRequestView = (props) => {
    let { history, location, match } = props;

    const formState = useSelector(state => state.FormData);
    const url = `/runsheets-last/view/${get(formState, `${getManifestListAPI.key}_view.runSheetLastId`, "")}`;
    const [recievedPackageCountString, setRecievedPackageCountString] = useState('-')

    useEffect(() => {
        callApi(`${getManifestListAPI.url}/${match.params.id}/published-packages/all`)
            .method("get")
            .headers(true)
            .send()
            .then((result) => {
                const packages = result?.data?.data ? result.data.data : []
                sessionStorage.setItem('printPackages', JSON.stringify(result.data.data))

                if (get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH") {
                    return `0`;
                } else {
                    let count = 0;

                    packages.forEach((value) => {
                        // if (value.status !== "PACKED" || value.status !== "IN_TRANSIT_FIRST") {
                        if (value.status !== "IN_TRANSIT_FIRST" && value.status !== "PACKED") {
                            count++;
                        }
                    });
                    setRecievedPackageCountString(`${count} / ${packages.length}`)
                }
            }).catch((error) => { })
    }, [])

    return (
        <div className="container-fluid">
            <FormWrapper
                setGroupName={`publised_package_view`}
                onDestroyUnsetFormObject={true}
                apiUrl={`${getManifestListAPI.url}/${match.params.id}/published-packages?page=1&limit=20`}
                onRebuildResponseFn={(response) => {
                    return {
                        data: response,
                        "_onLoad": false,
                    }
                }}
            >

                <FormWrapper
                    setGroupName={`${getManifestListAPI.key}_view`}
                    onDestroyUnsetFormObject={true}
                    apiUrl={`${getManifestListAPI.url}/${match.params.id}`}
                    onRebuildResponseFn={(response) => {
                        console.log("===========", response)
                        return {
                            id: response.id,
                            status: response.status,
                            code: response.code,
                            locationPick: response.locationPick,
                            locationDrop: response.locationDrop,
                            notes: response.notes,
                            pickAt: response.pickAt,
                            packages: response.packages,
                            merchant: get(response, `merchant.name`, ""),
                            runSheetLastId: response.runSheetLastId,
                            runSheetLast: response.runSheetLast,
                            orderCount: get(response, `merchant.orderCount`, ""),
                            packageCount: response.publishedPackages,
                            merchantObj: response.merchant,
                            // merchantObj: get(response, `merchant.orderCount`, {}),
                            driver: response.driver,
                            "_onLoad": false,
                        }
                    }}
                >
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box" style={{ padding: "24px 0 15px 0" }}>
                                <h4 className="font-size-18">View Pick Up Request</h4>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className={"d-flex justify-content-end"}>
                                <BackBtnComponent
                                    elementWrapperStyle={"mr-2"}
                                    isGoBack={true}
                                />
                                {
                                    get(formState, `${getManifestListAPI.key}_view.status`, "") === "IN_TRANSIT_FIRST" ? (
                                        <CreateBtn
                                            id={match.params.id}
                                            historyFn={history}
                                        />
                                    ) : null
                                }
                                {
                                    ((get(formState, `${getManifestListAPI.key}_view.status`, "") !== "COMPLETED") && (get(formState, `${getManifestListAPI.key}_view.status`, "") !== "REJECTED")) && (get(formState, `${getManifestListAPI.key}_view.status`, "") !== "IN_TRANSIT_FIRST") ? (
                                        <InputButton
                                            elementWrapperStyle={"mr-2"}
                                            btnText={"Edit"}
                                            startIcon={"mdi mdi-square-edit-outline"}
                                            onClickBtnFn={() => history.push(`/pick-requests/edit/${match.params.id}`)}
                                        />
                                    ) : (null)
                                }

                                {
                                    (get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH") ?
                                        (
                                            <Fragment>
                                                <UIModal
                                                    btnColor={"success"}
                                                    btnText={"Accept"}
                                                    startIcon={"far fa-check-circle"}
                                                    id={match.params.id}
                                                    history={history}
                                                    displayMessage={"Are you sure you want to accept this pick request?"}
                                                    status={"ACCEPTED"}
                                                    flashMessage={"Pick request has successfully accepted"}
                                                />

                                                <UIModal
                                                    btnColor={"danger"}
                                                    btnText={"Reject"}
                                                    startIcon={"far fa-times-circle"}
                                                    id={match.params.id}
                                                    history={history}
                                                    displayMessage={"Are you sure you want to reject this pick request?"}
                                                    status={"REJECTED"}
                                                    flashMessage={"Pick request has successfully rejected"}
                                                />
                                            </Fragment>
                                        ) : (get(formState, `${getManifestListAPI.key}_view.status`, "") === "ACCEPTED") ? (
                                            get(formState, `${getManifestListAPI.key}_view.runSheetLastId`, null) === null && get(formState, `${getManifestListAPI.key}_view.runSheetLineId`, null) === null ?
                                                (
                                                    <Fragment>
                                                        <UIModal
                                                            btnColor={"success"}
                                                            btnText={"Revert"}
                                                            startIcon={"far fa-times-circle"}
                                                            id={match.params.id}
                                                            history={history}
                                                            displayMessage={"Are you sure you want to revert this pick request?"}
                                                            status={"PUBLISH"}
                                                            flashMessage={"Pick request has successfully reverted"}
                                                        />

                                                        {/* <UIModal
                                                        btnColor={"success"}
                                                        btnText={"Complete"}
                                                        startIcon={"far fa-check-circle"}
                                                        id={match.params.id}
                                                        history={history}
                                                        displayMessage={"Are you sure you want to complete this pick request?"}
                                                        status={"COMPLETED"}
                                                        flashMessage={"Pick request has successfully completeed"}
                                                    /> */}
                                                    </Fragment>
                                                )
                                                :
                                                null) : (get(formState, `${getManifestListAPI.key}_view.status`, "") === "PICKED_UP" && (get(formState, `${getManifestListAPI.key}_view.runSheetLast.endAt`, "") !== null || get(formState, `${getManifestListAPI.key}_view.runSheetLine.endAt`, "") !== null)) ?
                                            (
                                                <Fragment>
                                                    <UIModal
                                                        btnColor={"success"}
                                                        btnText={"Complete"}
                                                        startIcon={"far fa-check-circle"}
                                                        id={match.params.id}
                                                        history={history}
                                                        displayMessage={"Are you sure you want to complete this pick request?"}
                                                        status={"COMPLETED"}
                                                        flashMessage={"Pick request has successfully completeed"}
                                                    />
                                                </Fragment>
                                            ) : null
                                }

                                <ManifestPrintModal
                                    manifest={get(formState, `${getManifestListAPI.key}_view`, {})}
                                />
                            </div>
                        </Col>
                    </Row>


                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-3">Package Details</h4>

                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-4"}>Manifest Code</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.code`, "")}</dt>

                                                <dd className={"col-sm-4"}>Pick Up Location</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationPick.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Drop Location</dd>
                                                <dt className={"col-sm-8"}>{get(formState, `${getManifestListAPI.key}_view.locationDrop.name`, "")}</dt>

                                                <dd className={"col-sm-4"}>Pick Up Date</dd>
                                                <dt className={"col-sm-8"}>
                                                    {get(formState, `${getManifestListAPI.key}_view.pickAt`, "") === null ? "-" : dateObjectToString(get(formState, `${getManifestListAPI.key}_view.pickAt`, ""), "YY-mm-dd")}
                                                </dt>
                                            </dl>
                                        </div>

                                        <div className={"col-md-6"}>
                                            <dl className={"row"}>
                                                <dd className={"col-sm-5"}>Merchant</dd>
                                                <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.merchant`, "")}</dt>

                                                <dd className={"col-sm-5"}>Status</dd>
                                                <dt className={"col-sm-7"}>
                                                    {
                                                        get(formState, `${getManifestListAPI.key}_view.status`, "") === "PUBLISH"
                                                            ? "PENDING"
                                                            : get(formState, `${getManifestListAPI.key}_view.status`, "") === "IN_TRANSIT_FIRST" ? "PICKED UP"
                                                                : get(formState, `${getManifestListAPI.key}_view.status`, "") === "COMPLETED" ? "PICKUP COMPLETED"
                                                                    : get(formState, `${getManifestListAPI.key}_view.status`, "")
                                                    }
                                                </dt>

                                                <dd className={"col-sm-5"}>Received Packages Count</dd>
                                                <dt className={"col-sm-7"}>{recievedPackageCountString}</dt>

                                                {/* <dd className={"col-sm-5"}>Packages Count</dd>
                                            <dt className={"col-sm-7"}>{get(formState, `${getManifestListAPI.key}_view.orderCount`, "")}</dt> */}

                                                <dd className={"col-sm-5"}>Runsheet Code</dd>
                                                <dt className={"col-sm-7"}><a href={url}>{get(formState, `${getManifestListAPI.key}_view.runSheetLast.code`, "")}</a></dt>
                                            </dl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <CrudComponent
                            title="Selected Packages"
                            apiURL={`${getManifestListAPI.url}/${match.params.id}/published-packages?page=1&limit=20`}
                            dataTableStateKey={`${getManifestListAPI.key}PickRequestView`}
                            isFilter={false}
                            bulkPrintAll={true}
                            headerBtn={<React.Fragment>
                                <BulkShippingLabelPrint
                                    type='bulk'
                                    className='btn btn-primary waves-effect waves-light mr-3'
                                />
                                {" "}
                            </React.Fragment>}
                            isSetAction={true}
                            isSetCreateAction={false}
                            isDropdownActionBtn={true}
                            tableHeaderList={[
                                { displayName: "Article No", key: "articleNo" },
                                { displayName: "Channel Order No", key: "order.channelOrderNo" },
                                { displayName: "Customer Name", key: "order.name" },
                                { displayName: "Delivery Type", key: "deliveryType" },
                                { displayName: "Payment Method", key: "order.paymentMethod" },
                                { displayName: "Package Category", key: "category.name" },
                                {
                                    displayName: "Status", key: "status", onPrintFn: (rawData) => {
                                        if (rawData.status === "COMPLETED") {
                                            return "PICKUP COMPLETED";
                                        } else if (rawData.status === "IN_TRANSIT_FIRST") {
                                            return "Package Picked Up";
                                        } else if (rawData.status === "RECEIVED") {
                                            return "Received at processing Center";
                                        } else if (rawData.status === "SORTED") {
                                            return "Ready for Transit";
                                        }
                                        else {
                                            return rawData.status;
                                        }
                                    }
                                },
                            ]}
                            actionList={[
                                {
                                    actionType: actionBtnTypes.view,
                                    isSetModel: false,
                                    actionBtn: {
                                        onClickBtnFn: (rawData) => history.push(`/${location.pathname.split('/')[1]}/package/view/${rawData.id}`),
                                    }
                                }
                            ]}
                        />
                    </div>

                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Notes</h4>

                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            {
                                                get(formState, `${getManifestListAPI.key}_view.notes`, []) != null &&
                                                    get(formState, `${getManifestListAPI.key}_view.notes`, []).length > 0 ? (
                                                    <ul style={{ paddingLeft: "17px" }}>
                                                        {
                                                            get(formState, `${getManifestListAPI.key}_view.notes`, []).map((element, index) => {
                                                                return (
                                                                    <li key={index}>{element.note} <span style={{ fontWeight: "500" }}>By {element.name}</span></li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                ) : (
                                                    <div>None</div>
                                                )
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </FormWrapper>
            </FormWrapper>


        </div>
    );
};
const CreateBtn = (props) => {
    return (
        <SubmitButton
            elementWrapperStyle={"mr-2"}
            btnText={"Complete Pick Request"}
            startIcon={"mdi mdi-content-save-all-outline"}
            formGroupName={`${getManifestListAPI.key}_view`}
            dataTableKey={getManifestListAPI.key}
            isValidate={true}
            // validationObject={{
            //     fields: {
            //         name: "Name",
            //         code: "Code",
            //         registrationNo: "Type",
            //         status: "Status",
            //     },
            //     rules: {
            //         name: "required",
            //         code: "required",
            //         registrationNo: "required",
            //         status: "required"
            //     },
            //     message: {}
            // }}
            flashMessages={{
                "success": {
                    status: true,
                    message: "Pick Request Completed",
                    type: "success"
                }
            }}
            callApiObject={{
                isSetHeaders: true,
                multipart: false,
                method: "put",
                onUpload: false
            }}
            apiDataStoringObject={{
                setLoader: true,
                storingType: apiResponseStoringType.apiRequestOnly,
                mergeToSuccessResponse: null,
                mergeToErrorResponse: null,
            }}
            onGetAPIEndPointFn={(formObject) => {
                return {
                    url: `${getManifestListAPI.url}/${props.id}`,
                    key: getManifestListAPI.key
                }
            }}
            onChangeRequestBodyFn={(formObject) => {


                return {
                    "status": "COMPLETED"

                }

            }}
            onResponseCallBackFn={(error, result) => {
                if (!error) {
                    props.historyFn.push(`/pick-requests`);
                }
            }}
        />
    )
}

export default PickRequestView;
